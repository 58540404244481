import { CONTACT_FORM_UPDATE, CONTACT_USER_FAIL, CONTACT_USER_SUCCESS, CONTACT_USER } from "../Action/types";

const INITIAL_STATE = {
    name: "",
    email: "",
    phone: "",
    website: "",
    subject: "",
    message: "",
    contactLoading: false,
    contactUser: '',
    contactError: '',


    // Request for Demo

    name: "",
    email: "",
    designation: "",
    organization: "",
    dateOfDemo: "",
    dateOfDemo2: "",
    dateOfDemo3: "",
    demoSubject:"",

    // Quick Apply Career 

    nameCareer: '',
    emailCareer: "",
    contact: "",
    currentAnnualCtc: "",
    resume: "",

};


export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case CONTACT_FORM_UPDATE:
            return { ...state, [action.payload.prop]: action.payload.value };
        case CONTACT_USER:
            return { ...state, contactLoading: true, contactError: '' };
        case CONTACT_USER_SUCCESS:
            return { ...state, contactLoading: action.payload, contactLoading: false, };
        case CONTACT_USER_FAIL:
            return { ...state, contactError: action.payload, contactLoading: false, };
        default:
            return state;
    }
};
