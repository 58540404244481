import React from "react"
import "./hero.css"
import homevideo from "../../../Video/HomeVideo2.mp4"
const Hero = () => {
  return (
    <>

<div className="hero">
      <div className="container">
      <div className="content">
        <div className="row justify-content-around ">
          <div className="col-lg-6 pt-2 mx-auto my-auto animate__animated animate__slideInUp" >
            <h1>When Code meets Conservation</h1>
            <h2 className="mb-4">We are team of software developers and subject matter experts blending code and conservation, crafting digitization platforms that delivers sustainable responsibility. With unwavering dedication to preserving our environment, we infuse every pixel and line of code with an aim for a greener future.</h2>
            <a href="/demo" alt="" className='btn-sm btn5'>Get Demo</a>
          </div>
          <div className="col-lg-6 mx-auto my-auto">
            <video className="homeVideo" autoPlay loop muted>
              <source src={homevideo}
                type="video/mp4"
                className="img-fluid"
                allowFullScreen
              />
            </video>
          </div>
          
        </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default Hero
