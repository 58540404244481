import React from "react"
import img from "../images/career2.jpeg"
import Back from "../common/Back"
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { careerPost, contactUsForm } from '../../Redux/Action/ContactUsAction';



const Careers = () => {
    const dispatch = useDispatch();
    let contactData = useSelector((state) => state.contactUs);
  
  
    const formEmpty = () => {
      contactData.nameCareer = '';
      contactData.emailCareer = '';
      contactData.contact = '';
      contactData.currentAnnualCtc = '';
      contactData.resume = '';
    }
  
    let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    const requestSubmit = () => {
  
      if (regexEmail.test(contactData.emailCareer)) {
        let formData = new FormData();
        formData.append("name", contactData.nameCareer);
        formData.append("email", contactData.emailCareer);
        formData.append("contact", contactData.contact);
        formData.append("currentAnnualCtc", contactData.currentAnnualCtc);
        formData.append("resume", contactData.resume);
  
        let l = 0
        for (const [key, value] of Object.entries(formData)) {
          if (value === '') l++;
        }
  
        if (l === 0) {
        
  
          dispatch(careerPost(formData)).then((res) => {
            formEmpty();
            toast.success("Details Added Successfully");
  
          })
          formEmpty();
        }
        else {
          toast.error('All input is required')
        }
      } else toast.error('Email Should be correct')
  
    }
  
  return (
    <>
      <section className='contact mb'>
        <Back title='Careers' cover={img} />
        <h4 style={{"marginBottom":"40px","marginTop":"30px"}}>Your Professional Career With Ehssas Digitech Pvt. Ltd.</h4>
      
        <div className='container'>
          
          <form className='shadow'>
           <h3 style={{"textAlign":"center","marginBottom":"30px"}}>Quick Apply</h3>
           <h4 style={{"textAlign":"center","marginBottom":"30px"}}>Just attach your CV below and send it to us. We'll take care of the rest. We ensure that we review every CV we receive.</h4>

            <div>
            <label style={{"marginRight":"100px"}}>Name</label>  
              <input type='text' style={{"marginRight":"20px"}} value={contactData.nameCareer} onChange={(e) => dispatch(contactUsForm({ prop: "nameCareer", value: e.target.value }))}/>
              <label style={{"marginRight":"150px"}}>Email</label>               
              <input type='text'  style={{"marginRight":"40px"}}  value={contactData.emailCareer} onChange={(e) => dispatch(contactUsForm({ prop: "emailCareer", value: e.target.value }))}  />
            </div>
            <div>
            <label style={{"marginRight":"95px"}}>Phone</label>  
            <input type="text"  style={{"marginRight":"20px"}}  value={contactData.contact} onChange={(e) => dispatch(contactUsForm({ prop: "contact", value: e.target.value }))}/>
            <label style={{"marginRight":"65px"}}>Current Annual CTC</label>   
            <input type='text'  value={contactData.currentAnnualCtc} onChange={(e) => dispatch(contactUsForm({ prop: "currentAnnualCtc", value: e.target.value }))}  />            
            </div>
            <div>
            <label style={{"marginRight":"25px"}}>Upload YOUR CV</label>   
            <input type='file' style={{"marginRight":"40px"}}  onChange={(e) => {
                      dispatch(contactUsForm({ prop: "resume", value: e.target.files[0] }))
                    }}/>      
            </div>
            <button onClick={requestSubmit}>Submit Request</button>
          </form>
        </div>
      </section>
    </>
  )
}

export default Careers
