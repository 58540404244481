import React from "react"
import Product from "./product/product"
import Hero from "./hero/Hero"
import Services from "../services/Services"





const Home = () => {
  return (
    <>
      <Hero />
      <Product />
    </>
  )
}

export default Home
