import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import 'bootstrap/dist/css/bootstrap.min.css';
import ReduxThunk from 'redux-thunk';
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from 'redux';
import reducers from './Redux/Reducer';
import { BrowserRouter } from "react-router-dom";
const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <App />
      </Provider>
    </BrowserRouter> 
  </React.StrictMode>
)
