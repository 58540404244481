import React, { useState } from "react";
import img from "../images/Demo.jpeg"
import vdo from "../../components/images/video.mp4";
import "./demo.css"
import Back from "../common/Back"
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { contactUsForm, requestDemoPost } from '../../Redux/Action/ContactUsAction';

const Demo = () => {
  const dispatch = useDispatch();
  let contactData = useSelector((state) => state.contactUs);


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const requestSubmit = () => {
    if (regexEmail.test(contactData.email)) {
      let data = {
        name: contactData.name,
        email: contactData.email,
        designation: contactData.designation,
        organization: contactData.organization,
        dateOfDemo: contactData.dateOfDemo,
        dateOfDemo2: contactData.dateOfDemo2,
        dateOfDemo3: contactData.dateOfDemo3,
        demoSubject: contactData.demoSubject,
      };


      if (contactData.name !== "" && contactData.email !== "" && contactData.designation !== "" && contactData.organization !== "" && contactData.dateOfDemo !== "" && contactData.demoSubject !== "" ) {
        dispatch(requestDemoPost({ data })).then((res) => {
        })
        handleClose();
        // formEmpty();
      }
      else {
        toast.error('All input is required')
      }
    } else toast.error('Email Should be correct')
  }
  return (
    <>
      <section className='contact mb'>
        <Back title='Demo' cover={img} />

        <div className='container'>
          
          <form className='shadow mb-5px'>
          <p>Our software platform enables you to digitalize your multicolor ESG aspects & provides reporting, auditing, prioritizing your KPIs as per Indian ESG requirements in compliance rate.</p>
            <h4>Fill form to get Demo</h4> <br />
            <div>
              <label style={{ "marginRight": "100px" }}>Name</label>
              <input type='text' style={{ "marginRight": "20px" }} value={contactData.name} onChange={(e) => dispatch(contactUsForm({ prop: "name", value: e.target.value }))} />
              <label style={{ "marginRight": "100px" }}>Email</label>
              <input type='text' value={contactData.email} onChange={(e) => dispatch(contactUsForm({ prop: "email", value: e.target.value }))} />
            </div>
            <div>
              <label style={{ "marginRight": "60px" }}>Designations</label>
              <input type="text" value={contactData.designation} onChange={(e) => dispatch(contactUsForm({ prop: "designation", value: e.target.value }))} />
              <label style={{ "marginRight": "55px" }}>Organizations</label>
              <input type='text' value={contactData.organization} onChange={(e) => dispatch(contactUsForm({ prop: "organization", value: e.target.value }))} />
            </div>
            <div>
              <label style={{ "marginRight": "40px" }}>First Demo Date</label>
              <input type='date' value={contactData.dateOfDemo} onChange={(e) => dispatch(contactUsForm({ prop: "dateOfDemo", value: e.target.value }))} />
              <label style={{ "marginRight": "20px" }}>Second Demo Date</label>
              <input type='date' value={contactData.dateOfDemo2} onChange={(e) => dispatch(contactUsForm({ prop: "dateOfDemo2", value: e.target.value }))} />
            </div>
            <div>
              <label style={{ "marginRight": "35px" }}>Third Demo Date</label>
              <input type='date' value={contactData.dateOfDemo3} onChange={(e) => dispatch(contactUsForm({ prop: "dateOfDemo3", value: e.target.value }))} />
              <label style={{ "marginRight": "35px" }}>Demo Subject</label>
              <input type='text' value={contactData.demoSubject} onChange={(e) => dispatch(contactUsForm({ prop: "demoSubject", value: e.target.value }))} />
            </div>
            <textarea cols='30' rows='10'></textarea>
            <button onClick={requestSubmit}>Submit Request</button>

          </form>
        </div>
      </section>
    </>
  )
}

export default Demo
