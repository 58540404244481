import "./App.css"
import Pages from "./components/pages/Pages"
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
    <Pages />
  <ToastContainer />
    </>
  ); 

}

export default App
