import React from "react"
// import { awards } from "../../data/Data"
import { Card, Row, Col, Button } from 'react-bootstrap'
import "./product.css"
import vision from "../../images/vision.jpeg"
import mission from "../../images/mission.jpeg"
import values from "../../images/values.jpeg"
import underLine from "../../images/board_line.jpg"
import "../featured/Featured.css"
import "../../services/Services.css"
import Heading from "../../common/Heading"
import hmclogo from "../../images/HMC_logo.png"
import dmcLogo from "../../images/dmcLogo.png"
import DPC from "../../images/DPC.gif"
const Product = () => {

  return (

    <>
      {/* <div className='data'> */}
      <div className="container">
        {/* <div className="content"> */}
        <div className="row justify-content-around pt-5 ">
          <div className="col-sm-4">
            <Card>
              <Card.Header>
                <img src={vision} alt="" />
              </Card.Header>
              <Card.Body>
                <h5>Our Vision</h5>
                <img className="img-fluid" src={underLine} alt="" />
                <p>Our vision foresees a world where every organization, irrespective of its sector, embraces bespoke digital solutions, unlocking process optimization, fueling growth, and igniting a global positive transformation.</p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-4">
            <Card>
              <Card.Header>
                <img src={mission} alt="" />
              </Card.Header>
              <Card.Body>
                <h5>Our Mission</h5>
                <img  className="img-fluid" src={underLine} alt="" />
                <p> We believe that by enabling businesses to optimize their processes, foster growth, and positively impact the world, we can pave the way for a more sustainable and technologically advanced society. We are committed to providing the tools and expertise necessary to bridge the gap between ambition and achievement, making our vision of a better, digitally-enhanced world a reality for everyone.</p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-4">
            <Card>
              <Card.Header>
                <img src={values} alt="" />
              </Card.Header>
              <Card.Body>
                <h5>Our Values</h5>
                <img className="img-fluid" src={underLine} alt="" />
                <p>Our values encompass integrity, innovation, collaboration, and sustainability. We prioritize integrity in all dealings, foster innovation to stay at the forefront, value collaboration as the cornerstone of success, and uphold sustainability as a commitment to a better future. These principles guide us in everything we do.</p>
              </Card.Body>
            </Card>
          </div>
        </div>
        {/* <div className='container'> */}
         {/* <div className="servicecard"> */}
         <Heading title='Our Clients' className="text-center m-0"/>
       <div className="row justify-content-around mb-3">
      
        <div className="col-sm-4 mb-2">
        <img src={hmclogo} alt="" className="img-responsive center-block d-block mx-auto client1logo" />
        </div>
        <div className="col-sm-4 mb-2">
        <img src={dmcLogo} alt="" className="img-responsive center-block d-block mx-auto client2logo" />
        </div>
        <div className="col-sm-4 mb-2">
        <img src={DPC} alt="" className="img-responsive center-block d-block mx-auto client3logo" />
        </div>
       </div>
          
         
          {/* </div> */}
        </div>

      {/* </div> */}
      {/* </div>
      </div> */}
    </>
  )
}

export default Product
