import React from "react"
import "./footer.css"
import logo from "../../images/NewLogo.png"
import { FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      {/* <section className='footerContact'> */}
        <div className='container'>
          <div className='send flex'>
            {/* <div className='text'>
              <h2>Ready to take meaningful action on your ESG journey?</h2>
              <p>We'll help you to grow your career and growth.</p>
            </div> */}
            {/* <a href="/demo" alt="" className='btn5' >Get Demo</a> */}
          </div>
        </div>
      {/* </section> */}
      <footer>
        <div className='container'>
          <div className='box'>
              <img src={logo} alt='' style={{"height":"80px","width":"300px"}} />
              <p>Ehssas Digitech, a software company that serves to clients from various regions.
              </p>
          </div>
          <div className="box">
            <h5>Head Office</h5>
            <p>223, 2nd Floor DLF Tower B, Jasola District Centre, New Delhi, Delhi - 110025, India</p>
          </div>
          <div className="box">
            <h5>New Delhi Office </h5>
            <p>514, 5th Floor DLF Tower B, Jasola District Centre, New Delhi, Delhi - 110025,  India</p>
          </div>
          <div className="box">
            <h5>Mumbai Office</h5>
            <p>Aditya Darshan Deen Dayal Upadhyay Road, Anand Nagar, Dombivli West, Dombivli, Maharashtra - 421202, India</p>
          </div>
          <div className="box">
            <h5>Contact Us</h5>
            <p>011-41032346</p>
            <p>contact@EhssasDigitech.com</p>
            <a href="https://www.linkedin.com/company/97869708/admin/feed/posts/" target="_blank" rel="noopener noreferrer">
        <FaLinkedin size={24} color="white"/></a>
          </div>
        </div>
      </footer>
      <div className='legal'>
        <span>© 2023 EHSSas Digitech.</span>
      </div>
    </>
  )
}

export default Footer
