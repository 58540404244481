
import React from "react"
import Back from "../common/Back"
import RecentCard from "../home/recent/RecentCard"
import "../home/recent/recent.css"
import img from "../images/event2.jpeg"

const Pricing = () => {
  return (
    <>
       <section className='blog-out mb'>
        <Back title='Event' cover={img} />
        <div className='container recent'>
          <RecentCard />
        </div>
      </section>
    </>
  )
}

export default Pricing
