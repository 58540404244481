import {
    CONTACT_USER, CONTACT_FORM_UPDATE, CONTACT_USER_SUCCESS, CONTACT_USER_FAIL,
} from "../Action/types";

import { APIClient } from '../../Api/APIClient';
import * as Constants from "./../../Constants"
import { toast } from 'react-toastify';
import { CAREERS, CONTACT_API, DEMO_REQUEST } from '../../EndPoint';



export const contactUsForm = ({ prop, value }) => {
    return {
        type: CONTACT_FORM_UPDATE,
        payload: { prop, value },
    };
}
/************************************** Register User Api request ****************************************************/
export const contactUsPost = ({ data }) => {
    return (dispatch) => {
        dispatch({ type: CONTACT_USER });
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(CONTACT_API, data)
                .then(response => {
                    ContactSuccess(dispatch, response)
                    toast.success("Details Added Successfully");
                    resolve(response)
                }).catch(error => {
                    let errorMessage = error.message
                    contactFail(dispatch, errorMessage);
                    toast.error(errorMessage);
                    reject(errorMessage)
                });
        });
    }
}


const ContactSuccess = (dispatch, user) => {
    dispatch({
        type: CONTACT_USER_SUCCESS,
        payload: user,
    });
};
const contactFail = (dispatch, errorMessage) => {
    dispatch({
        type: CONTACT_USER_FAIL,
        payload: errorMessage,
    });
};

// Request For Demo

/************************************** Register User Api request ****************************************************/
export const requestDemoPost = ({ data }) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(DEMO_REQUEST, data)
                .then(response => {
                    ContactSuccess(dispatch, response)
                    toast.success("Details Added Successfully");
                    resolve(response)
                }).catch(error => {
                    let errorMessage = error.message
                    contactFail(dispatch, errorMessage);
                    toast.error(errorMessage);
                    reject(errorMessage)
                });
        });
    }
}


/************************************** Quick Apply CV ****************************************************/
export const careerPost = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(CAREERS, data)
                .then(response => {
                    ContactSuccess(dispatch, response)
                    toast.success("Details Added Successfully");
                    resolve(response)
                }).catch(error => {
                    let errorMessage = error.message
                    contactFail(dispatch, errorMessage);
                    toast.error(errorMessage);
                    reject(errorMessage)
                });
        });
    }
}