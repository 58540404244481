// export const BASE_URL = 'http://apis-dev.ehssasdigitech.com/v1';

 export const BASE_URL = "http://192.168.1.8:43210/v1";

export const CONTACT_API = "/contactUs";

// Demo & Careers
export const DEMO_REQUEST = "/demoCareers/demoRequest";
export const CAREERS = "/demoCareers/careers";

// web cast
export const NOTIFICATION_API = "/admin/webcast";
