import { BASE_URL } from "../EndPoint";
import axios from "axios";
const APIClient = class APIClient {
    static myInstance = null;
    static getInstance() {
        if (APIClient.myInstance == null) {
            APIClient.myInstance = new APIClient();
        }
        return this.myInstance;
    }
    //************************************* Get *****************************************************************/
    get(endpoint, UserToken) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: `${BASE_URL}${endpoint}`,
                // data: data, // you are sending body instead
                headers: {
                    'x-access-token': UserToken,
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                resolve(res.data)
            })
                .catch(err => {
                    console.log("ErrorAxio get in APIClient-->", err)
                    reject({ message: err.response.data.messege })
                })
        });
    }
    ///******************************************* Get by URL *********************************************************** */
    getByUrl(endpoint, data, UserToken) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: `${BASE_URL}${endpoint}${data}`,
                // data: JSON.stringify(data), // you are sending body instead
                headers: {
                    'x-access-token': UserToken,
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                resolve(res.data)
            })
                .catch(err => {
                    console.log("ErrorAxio getByUrl in APIClient-->", err)
                    reject({ message: err.response.data.messege })
                })
        });
    }
    //************************* post *********************************************************************/
    post(endpoint, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: `${BASE_URL}${endpoint}`,
                data: data, // you are sending body instead

            }).then((res) => {
                resolve(res.data)
            })
                .catch(err => {
                    console.log("ErrorAxio post in APIClient--->", err)
                    if (err.response.data.errors === undefined) {
                        return reject({ message: err.response.data.message })
                    }
                    else {
                        return reject({ message: err.response.data.errors[0].msg })
                    }
                })
        });
    }
    //************************ delete *************************************************************************/
    delete(endpoint, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                url: `${BASE_URL}${endpoint}`,
                data: data,


            }).then((res) => {
                resolve(res.data)
            }).catch(err => {
                console.log("ErrorAxio delete in APIClient--->", err)
                reject(err)
            })

        });
    }
    //************************ delete by Url *************************************************************************/
    deleteByUrl(endpoint, data, UserToken) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                url: `${BASE_URL}${endpoint}${data}`,
                // data: data, // you are sending body instead
                headers: {
                    'x-access-token': UserToken,
                    'Content-Type': 'application/json',
                },

            }).then((res) => {
                resolve(res.data)
            }).catch(err => {
                console.log("ErrorAxio deleteByUrl in APIClient--->", err)
                reject(err)
            })

        });
    }
    //************************ edit  *****************************************************************************/
    put(endpoint, data, UserToken) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'put',
                url: `${BASE_URL}${endpoint}`,
                data: data, // you are sending body instead 
                headers: {
                    'x-access-token': UserToken,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                resolve(res.data)
            }).catch(err => {
                console.log("ErrorAxio edit in APIClient--->", err)
                reject(err)
            })
        });
    }

}
export { APIClient }; 