import React from "react"
import img from "../images/services.jpg"
import Back from "../common/Back"
import brsrVideo from "../../Video/Brsr.mp4"
import "../home/featured/Featured.css"
import "./Services.css"
import hmc from "../images/hmc1.png"
import hrms from "../images/hrms.png"
import dmc from "../images/dmcPage.png"
import dpc from "../images/dpcPage.png"
import fhcl from "../images/FHCL.png"
import { Card, Row, Col, Button, Tabs, Tab } from 'react-bootstrap'
const Services = () => {
  return (
    <>
      <section className='services mb'>
        <div className='featured container'>
          <div className="servicecard">
            <Tabs
              defaultActiveKey="brsr"
              id="justify-tab-example"
              className="mb-3" style={{ fontSize: "12px" }}
              justify
            >
              <Tab eventKey="brsr" title="BRSR">
                <h4>BUSINESS RESPONSIBILITY AND SUSTAINABILITY REPORTING (BRSR)</h4>
                <div className="row h-auto">
                  <div className="col">

                    <h6 style={{ textAlign: "left" }}>WHAT IS BRSR?</h6>
                    <p>Investors worldwide now value non-financial data in their project decisions. Businesses are more than profit-makers; they're social stewards. Transparency is key. Enter BRSR (Business Responsibility and Sustainability Reporting), an evolution of BRR. It's a one-stop hub for non-financial sustainability insights, benefitting investors, regulators, and the global public. This framework aligns with global reporting standards, prioritizing environmental, social, and governance disclosures, fostering sustainable growth and development.</p>

                    <h6 style={{ textAlign: "left" }}>Why BRSR?</h6>
                    <p>BRSR cultivates trust among stakeholders, from investors to consumers, by championing transparency and sustainability. It fuels long-term vision, goal-setting, efficient management, and organizational growth. BRSR spotlights strengths, draws top talent and investors, and positions businesses as leaders in their field, offering a distinct competitive edge.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <video className="videobrsr" autoPlay loop muted>
                      <source
                        src={brsrVideo}
                        type="video/mp4"
                        className="img-fluid"
                        allowFullScreen
                      />
                    </video>
                  </div>
                  <a href="http://brsr-v3.ehssasdigitech.com" alt="" target="_blank" ><button className='btn-sm btn6'>Get Subscription</button></a>
                 
                </div>
              </Tab>
              <Tab eventKey="hmc" title="HMC">
                <h4>Haryana Medical Council (HMC)</h4>
                <p>The entire process comprises multiple stages, encompassing registration, counseling, approval, document authentication, and certificate issuance.</p>
                <p style={{ "marginTop": "10px" }}>Here's the step-by-step process for Haryana Medical Counselling:</p>
                <p style={{ "marginTop": "10px" }}> 1. Registration of Doctors.</p>
                <p style={{ "marginTop": "10px" }}> 2. Medical  Consultation.</p>
                <p style={{ "marginTop": "10px" }}> 3. Approval of Counselling.</p>
                <p style={{ "marginTop": "10px" }}> 4. Verification of Documents.</p>
                <p style={{ "marginTop": "10px" }}> 5.	Certificate Generation.</p>
                <p style={{ "marginTop": "10px", "marginBottom": "20px" }}> 6.	News Notification.</p>
                <img src={hmc} style={{ "width": "500px" }} />
              </Tab>
              <Tab eventKey="dmc" title="DMC">
                <h4>Delhi Medical Council (DMC)</h4>
                <p >The pivotal stages that compose the process of Delhi Medical Counselling</p>
                <p style={{ "marginTop": "10px" }}> 1. Enrolment of Medical Professionals in Delhi</p>
                <p style={{ "marginTop": "10px" }}>2. Expert Medical Counselling</p>
                <p style={{ "marginTop": "10px" }}>3. Counselling Validation</p>
                <p style={{ "marginTop": "10px" }}> 4. Thorough Document Authentication</p>
                <p style={{ "marginTop": "10px" }}>5. Crafting their Certificate</p>
                <p style={{ "marginTop": "10px", "marginBottom": "20px" }}> 6. Stay Informed with News Updates</p>
                <img src={dmc} style={{ "width": "500px" }} />
              </Tab>
              <Tab eventKey="dpc" title="DPC">
                <h4 >Delhi Pharmacy Council (DPC)</h4>
                <p>The entire procedure encompasses a series of stages, encompassing registration, counselling, approval, document validation, and certificate creation.</p>
                <p style={{ "marginTop": "10px" }}>Here's the comprehensive protocol for Delhi Pharmacy Counselling:</p>
                <p style={{ "marginTop": "10px" }}>1.	Registration for Pharmacy Practitioners.</p>
                <p style={{ "marginTop": "10px" }}>2.	Pharmacist Consultation.</p>
                <p style={{ "marginTop": "10px" }}>3.	Counselling Authorization.</p>
                <p style={{ "marginTop": "10px" }}> 4.	Document Verification.</p>
                <p style={{ "marginTop": "10px" }}>5.	Certificate Crafting.</p>
                <p style={{ "marginTop": "10px", "marginBottom": "20px" }}> 6. News and Updates Notification.</p>
                <img src={dpc} style={{ "width": "500px" }} />

              </Tab>
              <Tab eventKey="hrms" title="HRMS">
                <h4>Human Resource Management System</h4>
                <p>In the vast universe of HRMS, a tapestry of options unfolds, a multibillion-dollar spectacle. Within this digital cosmos, the quintessential HRMS module emerges as a guiding star, illuminating the path for HR professionals. It bestows upon them the following functions:</p>

                 <p> Chronicles of Talent: A guardian of knowledge, it meticulously stores and organizes candidate and staff lore.</p>

                 <p> Benefits Alchemist: With precision, it conjures benefits plans and orchestrates the symphony of payroll, an alchemist of financial well-being.</p>

                  <p>Talent Whisperer: A sage in talent acquisition, it harmonizes the dance between seekers and providers, uniting them in purpose.</p>

                 <p> Engagement Artisan: As a morale guardian, it paints a canvas of satisfaction and belonging, nurturing retention and kindling engagement.</p>

                  <p>Time Sentinel: A vigilant timekeeper, it tracks attendance, aligning its flow with the currency of payroll.</p>

                <p>  Architect of Tomorrow: A visionary, it designs blueprints for future success, shaping the destiny of workforces and their succession.</p>
                <img src={hrms} style={{ "width": "500px" }} />
              </Tab>
              <Tab eventKey="crwc" title="CRWC">
                <h4>CRWS</h4>
                <p>CWC diversified its activities by creating a railway-side warehousing facility at the Whitefield Goods Terminal in Bangalore. This pilot project, initiated in February 2002 through an agreement with Indian Railways, successfully increased rail traffic, improved customer service, and boosted cargo volumes handled by CWC. This triumph inspired CWC to explore the development of Railside Warehousing Complexes near other identified rail terminals..</p>

              </Tab>
              <Tab eventKey="fhcl" title="FHEL">
                <h4>FHEL</h4>
                <p>Fresh & Healthy Enterprises Ltd. (FHEL), a subsidiary of Container Corporation of India, leads with innovation. Since 2006, we've offered state-of-the-art Controlled Atmosphere Storage, Cold Storage, and Custom Bonded Cold Storage. FHEL is your partner in freshness, storing diverse products like fruits, nuts, and supplements.</p>
                <img src={fhcl} style={{ "width": "500px" }} />
              </Tab>
              <Tab eventKey="SustainabilityReporting" title="SUSTAINABILITY REPORTING">
                <h5>GRI</h5>
                <p>Industries are the heartbeat of a nation's economy, but they also shape the world we live in. In today's ever-changing landscape, businesses must strive for more than profits; it's about responsible value creation. Our impact on communities and ecosystems matters. Sustainability reporting, guided by GRI Standards, is our pledge to transparency, sharing not just our economic success but our commitment to a sustainable future. It's a personal promise, a global effort, and a shared responsibility for a better world.</p>
                <h6 style={{ textAlign: "left" }}>Why choose GRI?</h6>
                <p> GRI Standards set you apart as a responsible and transparent organization, fostering stakeholder trust. These standards empower organizations to disclose their impacts effectively, benefiting a wide audience. Investors favor companies with ESG initiatives, and GRI offers a framework to define and communicate these impacts. It goes beyond profit and loss, helping you understand your ecological footprint, identify risks, seize opportunities, enhance stakeholder communication, and become a trusted player in a sustainable world. Choose GRI to shine in the new era of responsible business.</p>
                <h6 style={{ textAlign: "left" }}>What Do We do?</h6>
                <p> We specialize in simplifying GRI standards, offering a comprehensive solution for GRI reporting. Our services encompass the entire reporting process, from initial report drafting to ensuring consistent adherence to standards across your organization. We engage with your stakeholders and guide you in prioritizing key areas for your reports.</p>
                <p>We provide expertise in stakeholder engagement, materiality assessment, reporting, and the seamless integration of sustainable development goals into your company's operations. We meticulously align your organization's sustainable development objectives with GRI standards, showcasing your pivotal role in advancing global goals.</p>
                <h5>INTEGRATED REPORTING</h5>
                <p>Integrated reporting transcends traditional boundaries, showcasing a company's holistic impact on social, environmental, financial, and economic fronts. It's the future of corporate reporting, aligning with the evolving focus on intangible assets, fostering responsibility, trust, and transparency in our tech-driven world.</p>
                <h6 style={{ textAlign: "left" }}>Elevate Your Reporting with Integrated Reporting</h6>
                <p>Why embrace Integrated Reporting? It's your compass in a complex business world. It weaves together your strategy, governance, and performance within the broader social and environmental context. Here's why it shines:</p>
                <p>1.	A crystal-clear narrative of your business accessible to everyone.</p>
                <p>2.	Bridging the gap between intangible assets and business success.</p>
                <p>3.	Spotlighting potential risks, paving the way for proactive management.</p>
                <p>4.	Enhanced decision-making through deeper process insights.</p>
                <p>5.	Strengthened investor and stakeholder relations. Choose clarity, choose Integrated Reporting.</p>
                <h6 style={{ textAlign: "left" }}>Our Comprehensive Approach: Crafting Impactful Reports</h6>
                <p>We take you through a holistic report creation journey—from materiality analysis to value creation assessment. Our team delves deep into your organization's material issues, value creation, and strategy. We engage stakeholders, align processes, and provide integrated checklists. The result? A compelling integrated report, tailored to your organization's unique story.</p>
                <h5 ><b>SASB Reporting</b></h5>
                <h6 style={{ textAlign: "left" }}><b>SASB Standards</b></h6>
                <p>The need for transparent performance reporting is undeniable. The pandemic underscores the urgency for resilient practices. Enter SASB Standards: They pinpoint sustainability's financial core, delivering globally relevant, reliable data. Connecting businesses and investors, SASB leads the way in quantifying sustainability's financial impact.</p>
                <h6 style={{ textAlign: "left" }}> Unlocking Industry-Specific Solutions</h6>
                <p>SASB standards cater to each industry's unique sustainability dynamics. Together, they harmonize financial and sustainability data, offering a comprehensive corporate performance snapshot.</p>
                <p>In today's global landscape, sustainability isn't just a buzzword—it's a financial imperative. Addressing these issues yields lower costs, enhanced reputation, resilience, competitive advantages, and lasting value creation.</p>
                <h6 style={{ textAlign: "left" }}>Empowering Your Sustainability Journey</h6>
                <p>Our mission is to demystify SASB Standards, offering clarity on industry categories, topics, and metrics. We help you identify, manage, and report your sustainability challenges effectively, aligning with SASB. We assess your readiness, providing a smoother path to robust reporting through ongoing, iterative refinement, monthly and yearly checklists, and feedback loops. Elevate your company's sustainability performance with us.</p>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  )
}

export default Services
